<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ResourceCardContent',
  props: {
    color: String,
  },
  computed: {
    classes() {
      return `resource-card-content resource-card-content--${this.color}`;
    },
  },
};
</script>

<style lang="scss">
.resource-card-content {
  @apply p-8;

  // Split these up in case there are multiple content containers inside a card
  &:first-child {
    @apply rounded-tl-md rounded-tr-md;
  }

  &:last-child {
    @apply rounded-bl-md rounded-br-md;
  }

  h2 {
    @apply text-2xl leading-7 mb-4;
  }

  h2,
  h3 {
    @apply font-bold;
  }

  p {
    @apply text-sm;
  }

  &--light {
    @apply bg-white text-gray-500;

    h2,
    h3 {
      @apply text-gray-600;
    }
  }
}

.resource-card-content--dark {
  @apply bg-gray-700 text-white;

  p,
  .resources__action-link a {
    @apply text-gray-300;
  }
}
</style>
