<template>
  <MountingPortal mount-to="body" append>
    <ChecModal
      :header="$t('slack.joinCommerceJs')"
      class="slack-modal"
      width="sm"
      :overlay="dark ? 'dark' : 'light'"
      @dismiss="$emit('dismiss')"
    >
      <ChecLoading v-if="loading" />
      <template v-if="memberCount">
        <div class="slack-modal__logo-union space-x-6">
          <div class="slack-modal__logo slack-modal__logo--chec">
            <CommerceJsLogo alt="" />
          </div>
          <div>+</div>
          <div class="slack-modal__logo slack-modal__logo--slack">
            <ChecIcon icon="slack-color" class="w-full h-full" />
          </div>
        </div>
        <i18n path="slack.registeredCount" tag="div" class="slack-modal__lead-in">
          <template #count>
            <strong>{{ memberCount }}</strong>
          </template>
        </i18n>
        <form class="slack-modal__form space-y-2" @submit.prevent="invite">
          <div v-if="readableError" class="slack-modal__form-error">
            {{ readableError }}
          </div>
          <div v-if="inviteSent" class="slack-modal__form-success">
            {{ $t('slack.inviteSent') }}
          </div>
          <TextField
            v-model="email"
            required
            placeholder="you@yourdomain.com"
            name="email"
            type="email"
          />
          <ChecButton color="primary" button-type="submit">
            {{ $t('slack.getMyInvite') }}
          </ChecButton>
        </form>
        <div class="slack-modal__open-slack space-y-2">
          <div>
            {{ $t('slack.alreadyJoined') }}
          </div>
          <ChecButton
            tag-type="link"
            color="secondary"
            href="https://commercejs.slack.com"
            target="_blank"
          >
            {{ $t('slack.openSlack') }}
            <template #icon>
              <ChecIcon icon="slack-color" class="w-4 h-4" />
            </template>
          </ChecButton>
        </div>
      </template>
    </ChecModal>
  </MountingPortal>
</template>

<script>
import {
  ChecButton,
  ChecIcon,
  ChecLoading,
  ChecModal,
  TextField,
} from '@chec/ui-library';
import { MountingPortal } from 'portal-vue';
import CommerceJsLogo from '@/assets/svgs/chec-logo-hologram.svg';

export default {
  name: 'SlackSignupModal',
  components: {
    ChecButton,
    ChecIcon,
    ChecLoading,
    ChecModal,
    CommerceJsLogo,
    MountingPortal,
    TextField,
  },
  props: {
    dark: Boolean,
  },
  data() {
    return {
      loading: true,
      memberCount: null,
      email: '',
      inviteSent: false,
      inviteError: null,
    };
  },
  computed: {
    readableError() {
      if (!this.inviteError) {
        return '';
      }

      /* eslint-disable vue/script-indent */
      switch (this.inviteError) {
        case 'already_in_team':
          return this.$t('slack.error.alreadyInTeam');
        case 'already_in_team_invited_user':
          return this.$t('slack.error.alreadyInvited');
        case 'missing_email':
          return this.$t('slack.error.missingEmail');
        case 'invalid_email':
          return this.$t('slack.error.invalidEmail');
        default:
          return '';
      }
      /* eslint-enable vue/script-indent */
    },
  },
  mounted() {
    fetch(`${process.env.VUE_APP_MARKETING_SITE}/.netlify/functions/slack/count`)
      .then((response) => response.json()).then((count) => {
        this.loading = false;
        this.memberCount = count;
      });
  },
  methods: {
    invite() {
      this.inviteError = null;
      fetch(`${process.env.VUE_APP_MARKETING_SITE}/.netlify/functions/slack/invite?email=${encodeURIComponent(this.email)}`)
        .then((response) => response.json())
        .then((json) => {
          if (json.ok) {
            this.inviteSent = true;
            return;
          }

          this.inviteSent = false;
          this.inviteError = json.error;
        });
    },
  },
};
</script>

<style lang="scss">
.slack-modal {
  h2 {
    @apply text-lg font-bold;
  }

  &__lead-in {
    @apply mt-4 text-center;
  }

  &__form-error {
    @apply text-red-500;
  }

  &__form-success {
    @apply text-green-600;
  }

  &__form {
    @apply mt-8;

    .button {
      @apply w-full;
    }
  }

  &__open-slack {
    @apply mt-8 text-center;

    .button {
      @apply w-full;
    }
  }

  &__logo-union {
    @apply flex items-center text-xl font-bold justify-center;
  }

  &__logo {
    @apply w-20 h-20 flex items-center justify-center rounded-full;

    svg {
      @apply w-8 h-8;
    }

    &--chec {
      @apply bg-gray-700;
    }

    &--slack {
      @apply bg-white;
    }
  }
}
</style>
