<template>
  <div class="resource-card-header">
    <ChecIcon
      v-if="icon === 'slack-color'"
      :icon="icon"
      class="resource-card-header__icon"
    />
    <ChecMarketingIcon
      v-else
      :icon="icon"
      class="resource-card-header__icon"
    />
    <span class="resource-card-header__text">
      {{ text }}
    </span>
    <ChecButton
      class="resource-card-header__close"
      icon="close"
      variant="small"
      outline
      @click="$emit('dismiss')"
    />
  </div>
</template>

<script>
import { ChecButton, ChecIcon, ChecMarketingIcon } from '@chec/ui-library';

export default {
  name: 'ResourceCardHeader',
  components: {
    ChecButton,
    ChecIcon,
    ChecMarketingIcon,
  },
  props: {
    icon: String,
    text: String,
  },
};
</script>

<style lang="scss">
.resource-card-header {
  @apply flex items-center mb-4;

  &__icon {
    @apply w-8 h-8 mr-4;
  }

  &__text {
    @apply flex-grow caps-xxs;
  }
}

// Hack to make button text dark on light backgrounds (in parent)
.resource-card-content--light {
  .button--outline.resource-card-header__close {
    @apply text-gray-600 border-gray-600;
  }
}
</style>
