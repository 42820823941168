<template>
  <div v-if="merchant && !hasCompletedSetup" class="getting-started">
    <div class="getting-started__content">
      <h1 class="getting-started__title">
        {{ title }}
      </h1>
      <p class="getting-started__subtitle">
        {{ $t('home.welcomeSubtitle') }}
      </p>
      <ul class="getting-started__steps space-y-6">
        <li
          v-for="{ route, name, complete } in setupSteps"
          :key="name"
          class="getting-started__step"
          :class="{ 'getting-started__step--complete': complete }"
        >
          <div
            class="getting-started__step-check"
            :class="{ 'getting-started__step-check--complete': complete }"
          >
            <ChecIcon v-if="complete" icon="check" class="getting-started__step-icon" />
          </div>
          <router-link v-if="!complete" :to="{ name: route }">
            {{ name }}
            <ChecIcon icon="right-arrow" class="getting-started__step-arrow" />
          </router-link>
          <template v-else>
            {{ name }}
          </template>
        </li>
      </ul>
    </div>

    <div class="getting-started__image">
      <RaysGraphic v-if="signupPurpose === 'rays'" />
      <DashboardGraphic v-else />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { ChecIcon } from '@chec/ui-library';
import DashboardGraphic from '../assets/dashboard.svg';
import RaysGraphic from '../assets/rays.svg';

export default {
  name: 'GettingStarted',
  components: {
    ChecIcon,
    DashboardGraphic,
    RaysGraphic,
  },
  computed: {
    ...mapState('merchant', ['merchant']),
    ...mapGetters('merchant', ['setupSteps', 'hasCompletedSetup']),
    signupPurpose() {
      return this.merchant && this.merchant.signup_purpose;
    },
    title() {
      if (this.signupPurpose === 'rays') {
        return this.$t('home.gettingStartedRays');
      }
      return this.$t('home.gettingStarted');
    },
  },
};
</script>

<style lang="scss">
.getting-started {
  @apply flex text-gray-500 mb-32;

  &__content {
    @apply flex flex-col flex-grow justify-center;
  }

  &__image {
    @apply hidden;
    max-height: 400px;
    // See Figma for these dimensions
    max-width: 544px;

    @screen lg {
      @apply block w-1/2;
    }
  }

  &__title {
    @apply text-4xl font-bold mb-4 text-gray-600;
  }

  &__subtitle {
    @apply text-lg;
  }

  &__steps {
    @apply mt-8;
  }

  &__step {
    @apply flex items-center font-bold;

    &--complete {
      @apply text-green-600;
    }

    &:not(:last-child) {
      // Styles for the little connecting dots between steps
      .getting-started__step-check {
        &::after {
          @apply block w-2 h-2 bg-gray-300 rounded-full;
          content: '';
          margin-left: 2px;
          margin-top: 1.9rem;
        }

        &--complete::after {
          @apply bg-green-500;
          margin-top: 1.2rem;
        }
      }
    }
  }

  &__step-check {
    @apply bg-gray-300 rounded-full w-8 h-8 mr-2;
    padding: 10px; // controls icon size

    &--complete {
      @apply bg-green-500 text-white;
    }
  }

  &__step-arrow {
    @apply inline w-4 h-4 ml-2;
  }
}
</style>
