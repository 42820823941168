<template>
  <div class="home__contact">
    <h3 class="home__contact-heading">
      {{ $t('home.speakToAnExpert') }}
    </h3>
    <p class="home__contact-copy">
      {{ $t('home.speakToCopy') }}
    </p>
    <ChecButton
      color="primary"
      tag-type="link"
      icon-position="after"
      icon="right-arrow"
      href="https://commercejs.com/contact"
      target="_blank"
      rel="noopener nofollow"
    >
      {{ $t('home.messageUs') }}
    </ChecButton>
  </div>
</template>

<script>
import { ChecButton } from '@chec/ui-library';

export default {
  name: 'Contact',
  components: {
    ChecButton,
  },
};
</script>

<style lang="scss">
.home {
  &__contact {
    @apply max-w-lg p-8 text-gray-500 mx-auto mb-32 bg-white rounded-md shadow-sm;
  }

  &__contact-copy {
    @apply mb-8;
  }

  &__contact-heading {
    @apply text-2xl text-gray-600 font-bold mb-4;
  }
}
</style>
