<template>
  <div class="home__announcements">
    <h3 class="home__announcements-title">
      {{ $t('home.latestAnnouncements') }}
    </h3>

    <div class="home__announcements-list">
      <Announcement
        v-for="post in posts"
        :key="post.id"
        :date="$moment(post.publishDate).format('D MMM YYYY')"
        :title="post.title"
        :content="post.description"
        :link="`https://commercejs.com/blog/${post.slug}`"
      />
    </div>
  </div>
</template>

<script>
import Announcement from './Announcement.vue';
import contentfulPosts from '../../../../content/posts.json';

export default {
  name: 'Announcements',
  components: {
    Announcement,
  },
  computed: {
    posts() {
      return contentfulPosts;
    },
  },
};
</script>

<style lang="scss">
.home {
  &__announcements {
    @screen lg {
      max-width: 520px;
    }
  }

  &__announcements-title {
    @apply font-bold text-gray-600 text-xl;
  }
}
</style>
