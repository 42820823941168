<template>
  <div class="home">
    <template v-if="!isLoading">
      <GettingStarted />
      <Resources v-if="loaded" />
      <div class="home__updates">
        <Announcements />
        <LatestChanges />
      </div>
      <hr class="home__divider">
      <Contact />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { actions as preferenceActions } from '@/modules/users/store/preferences';
import GettingStarted from '../components/GettingStarted.vue';
import Resources from '../components/Resources.vue';
import Announcements from '../components/Announcements.vue';
import LatestChanges from '../components/LatestChanges.vue';
import Contact from '../components/Contact.vue';

export default {
  name: 'Home',
  components: {
    Announcements,
    Contact,
    GettingStarted,
    LatestChanges,
    Resources,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapGetters(['isLoading']),
  },
  async mounted() {
    await this.loadPreference('dismissed-getting-started-posts');
    this.loaded = true;
  },
  methods: {
    ...mapActions('users/preferences', {
      loadPreference: preferenceActions.LOAD_PREFERENCE,
    }),
  },
};
</script>

<style lang="scss">
.home {
  &__updates {
    @apply flex flex-col w-full justify-between;

    @screen lg {
      @apply flex-row;
    }
  }

  &__divider {
    @apply my-32 text-gray-300;
  }
}
</style>
