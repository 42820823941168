<template>
  <div class="resource-card">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ResourceCard',
};
</script>

<style lang="scss">
.resource-card {
  @apply shadow rounded-md mb-8 w-full;

  @screen xl {
    max-width: 520px;
  }
}
</style>
