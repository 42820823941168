<template>
  <div class="home__changes">
    <div class="home__changes-heading-container">
      <h3 class="home__changes-heading">
        {{ $t('home.latestChanges') }}
      </h3>
      <a
        href="https://commercejs.com/docs/release-notes/"
        target="_blank"
        rel="noopener nofollow"
        class="home__changes-link"
      >
        {{ $t('home.viewChangelog') }}
        <ChecIcon icon="right-arrow" class="home__changes-changelog" />
      </a>
    </div>

    <div class="home__changes-list">
      <ChangeLog
        v-for="(changes, updateDate) in updates"
        :key="updateDate"
        :updates="changes"
        :update-date="updateDate"
      />
    </div>
  </div>
</template>

<script>
import { ChangeLog, ChecIcon } from '@chec/ui-library';
import groupBy from 'lodash.groupby';
import mapValues from 'lodash.mapvalues';
import omit from 'lodash.omit';
import contentfulUpdates from '../../../../content/updates.json';

export default {
  name: 'LatestChanges',
  components: {
    ChangeLog,
    ChecIcon,
  },
  props: {
    /**
     * The maximum number of updates to show from Contentful, in descending order
     */
    show: {
      type: Number,
      default: 7,
    },
  },
  computed: {
    updates() {
      // created example: 2020-03-02Y12:34:56. First 10 is the Y-m-d part. We format it
      // into "January 14th, 2020" format.
      const groupFunction = (update) => {
        const created = update.created.substring(0, 10);

        return this.$moment(created, 'YYYY-MM-DD').format('MMMM D, YYYY');
      };

      // Groups by month
      return mapValues(
        groupBy(contentfulUpdates.slice(0, this.show), groupFunction),
        (updateList) => updateList.map((update) => ({
          ...omit(update, groupFunction),
          variant: update.type === 'update' ? 'updated' : update.type,
        })),
      );
    },
  },
};
</script>

<style lang="scss">
.home {
  &__changes {
    @apply mt-8;

    @screen lg {
      @apply mt-0;
      max-width: 520px;
    }
  }

  &__changes-heading-container {
    @apply flex mb-8;
  }

  &__changes-heading {
    @apply font-bold text-gray-600 text-xl flex-grow;
  }

  &__changes-changelog {
    @apply w-4 h-4 inline;
  }

  &__changes-list {
    @apply text-gray-500;
  }
}
</style>
