<template>
  <div v-if="hasVisibleResources">
    <div class="resources">
      <ResourceCard
        v-for="resource in resources"
        :key="resource.id"
      >
        <ResourceCardContent :color="resource.color || 'light'">
          <ResourceCardHeader
            :text="resource.title"
            :icon="resource.icon"
            @dismiss="handleDismiss(resource.id)"
          />

          <div v-html="contentAsHtml(resource.content)" />

          <div v-if="resource.media" class="resources__media">
            <img
              v-if="fileType(resource.media) === 'image'"
              class="resources__media--image"
              :src="resource.media.fields.file.url"
              :alt="resource.media.fields.title"
            >
            <video
              v-else-if="fileType(resource.media) === 'video'"
              class="resources__media--video"
              autoplay
              muted
              loop
            >
              <source :src="resource.media.fields.file.url">
              Your browser doesn't support inline videos.
            </video>
          </div>

          <p
            v-if="resource.link && resource.linkText"
            class="resources__action-link"
          >
            <a
              :href="resource.link"
              :target="resource.openInNewTab ? '_blank' : '_self'"
              rel="noopener nofollow"
            >
              {{ resource.linkText }}
              <ChecIcon icon="right-arrow" class="resources__action-link-icon" />
            </a>
          </p>
        </ResourceCardContent>
      </ResourceCard>

      <!-- todo put these other two cards into Contentful one day -->
      <ResourceCard v-if="isShown('custom:for-developers')">
        <ResourceCardContent color="light">
          <ResourceCardHeader
            text="For developers"
            icon="code-gray"
            @dismiss="handleDismiss('custom:for-developers')"
          />

          <h2>Start building</h2>
          <p>
            Get started with our flexible, lightweight, yet powerful APIs.
            Build custom storefronts, product displays, carts, and checkouts.
          </p>
        </ResourceCardContent>

        <ResourceCardContent color="dark">
          <h3 class="resources__install-header">
            Install the Commerce.js SDK
          </h3>
          <!-- eslint-disable-next-line max-len -->
          <pre class="resources__code bg-code mb-8"><code>npm install @chec/commerce.js</code></pre>

          <h3 class="resources__install-header">
            Install the CLI
          </h3>
          <!-- eslint-disable-next-line max-len -->
          <pre class="resources__code bg-code mb-8"><code>npm install -g @chec/cli</code></pre>

          <h3
            v-if="publicKey !== '' || secretKey !== ''"
            class="resources__install-header"
          >
            API keys
          </h3>
          <template v-if="publicKey !== ''">
            <h4 class="resources__code-header">
              Sandbox public key
            </h4>
            <CopyInline
              :copy-content="publicKey || ''"
            >
              <pre class="resources__code bg-code"><code>{{ publicKey }}</code></pre>
            </CopyInline>
          </template>
          <template v-if="secretKey !== ''">
            <h4 class="resources__code-header">
              Sandbox secret key
            </h4>
            <CopyInline
              :copy-content="secretKey || ''"
            >
              <pre class="resources__code bg-code"><code>{{ secretKey }}</code></pre>
            </CopyInline>
          </template>
          <hr class="resources__divider">

          <div class="resources__links">
            <div class="resources__links-section">
              <h3 class="resources__links-header">
                Getting started
              </h3>
              <ul class="resources__links-list space-y-3">
                <li class="resources__link">
                  <a href="https://commercejs.com/docs/sdk/products" target="_blank" rel="noopener">
                    Listing products
                  </a>
                </li>
                <li class="resources__link">
                  <a href="https://commercejs.com/docs/sdk/cart" target="_blank" rel="noopener">
                    Creating a cart
                  </a>
                </li>
                <li class="resources__link">
                  <a href="https://commercejs.com/docs/sdk/checkout" target="_blank" rel="noopener">
                    Creating a checkout
                  </a>
                </li>
              </ul>
            </div>
            <div class="resources__links-section">
              <h3 class="resources__links-header space-y-3">
                Tutorials
              </h3>
              <ul class="resources__links-list space-y-3">
                <li class="resources__link">
                  <a href="https://commercejs.com/docs/community/create-a-product-listing-with-next-js" target="_blank" rel="noopener">
                    Listing products with Next.js
                  </a>
                </li>
                <li class="resources__link">
                  <a href="https://commercejs.com/docs/community/create-a-gatsby-store-commercejs" target="_blank" rel="noopener">
                    Create a store with Gatsby
                  </a>
                </li>
                <li class="resources__link">
                  <a href="https://commercejs.com/docs/community/custom-input-fields-for-sanity-io" target="_blank" rel="noopener">
                    Product inputs with Sanity.io
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </ResourceCardContent>
      </ResourceCard>

      <ResourceCard v-if="isShown('custom:slack')">
        <ResourceCardContent color="light">
          <ResourceCardHeader
            :text="$t('slack.slack')"
            icon="slack-color"
            @dismiss="handleDismiss('custom:slack')"
          />

          <h2>{{ $t('slack.joinUs') }}</h2>
          <p>{{ $t('slack.description') }}</p>
          <ChecButton
            color="primary"
            class="resources__slack-button"
            @click="showSlackModal = true"
          >
            {{ $t('slack.join') }}
          </ChecButton>
        </ResourceCardContent>
      </ResourceCard>

      <SlackSignupModal v-if="showSlackModal" @dismiss="showSlackModal = false" />
    </div>
    <hr class="home__divider">
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { ChecButton, ChecIcon } from '@chec/ui-library';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { actions as preferenceActions } from '@/modules/users/store/preferences';
import { actions as apiKeyActions, getters as apiKeyGetters } from '@/modules/merchant/store/api-keys';
import CopyInline from '@/components/CopyInline.vue';
import ResourceCard from './ResourceCard.vue';
import ResourceCardContent from './ResourceCardContent.vue';
import ResourceCardHeader from './ResourceCardHeader.vue';
import SlackSignupModal from './SlackSignupModal.vue';
import contentfulResources from '../../../../content/resources.json';

export default {
  name: 'Resources',
  components: {
    ChecButton,
    ChecIcon,
    CopyInline,
    ResourceCard,
    ResourceCardContent,
    ResourceCardHeader,
    SlackSignupModal,
  },
  data() {
    return {
      showSlackModal: false,
    };
  },
  computed: {
    ...mapState('users/preferences', ['preferences']),
    ...mapGetters('merchant/apiKeys', { getApiKey: apiKeyGetters.GET_KEY_BY_TYPE }),
    resources() {
      return contentfulResources.filter((resource) => this.isShown(resource.id));
    },
    dismissedPosts() {
      const pref = this.preferences
        .find((candidate) => candidate.key === 'dismissed-getting-started-posts');

      if (!pref || typeof pref !== 'object' || !Array.isArray(pref.preference)) {
        return [];
      }

      return pref.preference;
    },
    hasVisibleResources() {
      return this.resources.length > 0
        || !this.dismissedPosts.includes('custom:slack')
        || !this.dismissedPosts.includes('custom:for-developers');
    },
    /**
     * Returns the first public key
     *
     * @returns {string}
     */
    publicKey() {
      const keys = this.getApiKey('public', true);
      if (keys.length > 0) {
        return keys[0].key;
      }
      return '';
    },
    /**
     * Returns the first secret key
     *
     * @returns {string}
     */
    secretKey() {
      const keys = this.getApiKey('secret', true);
      if (keys.length > 0) {
        return keys[0].key;
      }
      return '';
    },
  },
  mounted() {
    this.loadApiKeys();
  },
  methods: {
    ...mapActions('merchant/apiKeys', {
      loadApiKeys: apiKeyActions.LOAD_KEYS,
    }),
    ...mapActions('users/preferences', {
      setPreference: preferenceActions.SET_PREFERENCE,
    }),
    contentAsHtml(content) {
      return documentToHtmlString(content);
    },
    /**
     * Returns the first half of the media's mime type
     * @param {string} media e.g. "image/jpeg"
     * @returns {string} e.g. "image"
     */
    fileType(media) {
      if (!media) {
        return '';
      }
      return media.fields.file.contentType.split('/').shift();
    },
    isShown(slug) {
      return !this.dismissedPosts.includes(slug);
    },
    handleDismiss(slug) {
      this.setPreference({
        key: 'dismissed-getting-started-posts',
        preference: [...this.dismissedPosts, slug],
      });
    },
  },
};
</script>

<style lang="scss">
.resources {
  @apply flex flex-col content-between;

  @screen xl {
    // Try to contain 4 cards worth of content into 2 columns
    max-height: 1500px;
  }

  @screen xl {
    @apply flex-wrap;
  }

  &__action-link {
    @apply mt-8 text-right;
  }

  &__action-link-icon {
    @apply inline w-4 h-4 -mt-1;
  }

  // Fixed styles for the fixed content part of this stuff
  &__install-header {
    @apply text-lg mb-4;
  }

  &__code {
    // Can't use bg-code-block from UI library since it has a relative file path
    @apply p-4 rounded overflow-x-scroll mb-4 select-all;
  }

  &__code-header {
    @apply caps-xxs mb-1;
  }

  &__divider {
    @apply my-8 text-gray-400;
  }

  &__links {
    @apply flex;
  }

  &__links-section {
    @apply w-1/2;
  }

  &__links-header {
    @apply mb-4;
  }

  &__link a {
    @apply text-white;

    &:hover,
    &:focus {
      @apply text-gray-300;
    }
  }

  &__media {
    @apply mt-8;
  }

  &__slack-button {
    @apply mt-8;
  }
}
</style>
