<template>
  <a
    :href="link"
    :aria-label="title"
    class="home__announcement-link"
    target="_blank"
    rel="nofollow noopener"
  >
    <ChecCard class="home__announcement" hoverable>
      <p class="home__announcement-date">
        {{ date }}
      </p>
      <h4 class="home__announcement-header">
        {{ title }}
      </h4>
      <p class="home__announcement-content">
        {{ content }}
      </p>
    </ChecCard>
  </a>
</template>

<script>
import { ChecCard } from '@chec/ui-library';

export default {
  name: 'Announcement',
  components: {
    ChecCard,
  },
  props: {
    link: String,
    title: String,
    date: String,
    content: String,
  },
};
</script>

<style lang="scss">
.home {
  &__announcement {
    @apply text-gray-600 border-gray-300 mt-8 bg-white rounded-md shadow-sm;

    &:hover {
      .home__announcement-header {
        @apply underline;
      }
    }
  }

  &__announcement-date {
    @apply caps-xxs text-gray-500 mb-2;
  }

  &__announcement-header {
    @apply text-lg font-bold mb-2;
  }

  &__announcement-content {
    @apply text-sm;
  }
}
</style>
